<template>
  <div class="page page-xprogrammatic-all-channel_subscriptions">

    <PageHeader
    :icon="$t('CHANNEL_SUBSCRIPTION.ICON')"
    :title="$t('CHANNEL_SUBSCRIPTION.TITLES')"
    >

      <template slot="nav">
        <small><strong>{{$tc('CHANNEL_SUBSCRIPTION.TITLE_COUNT',count)}}</strong></small>
      </template>

      <permission name="channel_subscription" action="create">
        <v-tooltip bottom transition="none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              depressed
              color="success"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="newItem"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{ $t('COMMON.ADD') }}
        </v-tooltip>
      </permission>
    </PageHeader>

    <FormChannelSubscriptionStepper
      :show="showNew"
      @close="showNew = false"
      @create="goToItem"
    />

    <v-dialog
      v-model="dialog"
      width="680px"
      persistent
      overlay-color="transparent"
    >
      <FormChannelSubscriptionCompactContractsForm
        :id="contractId"
        :props="contractProps"
        :values="values"
        :loaded_values="loaded_values"
        @update="loadItems(),closeDialog()"
        @close="closeDialog"
      />
    </v-dialog>

    <Side
      component="forms/channel_subscription/compact/FormChannelSubscriptionCompact"
      :ids="(selected || []).map((v) => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="goToItem"
      @update="loadItems"
    />

    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="channel.title"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />

    <PageMain>
      <List
        mode="accordion"
        :items="items"
        :selected="selected"
        :count="count"
        :offset="offset"
        :limit="limit"
        :selectable="false"
        :pagination="true"
        cols="6"
        md="4"
        lg="3"
        xl="2"
        @select="selectItems"
        @page="changePage"
        @reload="searchItems"
      >
        <template v-slot:header="">
           <v-col cols="6" md="5" lg="4">
            <v-text-field
              rounded
              ref="where.channel.title"
              hide-details
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('COMMON.SEARCH')"
              @keyup="searchItems"
              @click:clear="searchItems"
            />
            </v-col>
        </template>

        <template v-slot:subheader="">
          <v-tabs background-color="transparent" slider-color="accent" height="36px">
            <v-tab @click="channelMethod = 'none'">{{$t("COMMON.ALL")}}</v-tab>
            <v-tab @click="channelMethod = 'organic'">{{$t("CHANNEL_SUBSCRIPTION.METHOD_ORGANIC")}}</v-tab>
            <v-tab @click="channelMethod = 'credit'">{{$t("CHANNEL_SUBSCRIPTION.METHOD_CREDITS")}}</v-tab>
            <v-tab @click="channelMethod = 'aggregator'">{{$t("CHANNEL_SUBSCRIPTION.METHOD_AGGREGATOR")}}</v-tab>
            <v-tab @click="channelMethod = 'display'">{{$t("CHANNEL_SUBSCRIPTION.METHOD_DISPLAY")}}</v-tab>
            <!-- <v-tab @click="channelMethod = 'xtrading'">{{$t("CHANNEL_SUBSCRIPTION.METHOD_XTRADING")}}</v-tab> -->
          </v-tabs>
        </template>

        <template v-slot:filters="">
          <div>
            <p class="mb-0 primary--text">{{ $t('INPUT.STATUS') }}</p>
            <v-select
              class="none"
              v-model="status"
              multiple
              :items="$t('STATUS_ITEMS')"
              item-text="text"
              item-value="value"
              ref="where.status"
            />
            <template v-for="(item, i) in $t('STATUS_ITEMS')">
              <v-checkbox
                v-model="status"
                :key="`sta${i}`"
                :label="item.text"
                :value="item.value"
                hide-details
                class="mt-0"
              />
            </template>
          </div>
        </template>

        <template v-slot:nav="">
          <!-- <v-btn-toggle v-model="viewMode" mandatory>
            <v-btn value="medias-col">
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>

            <v-btn value="medias-list">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </v-btn-toggle> -->
        </template>

        <template v-slot:empty="">
          <div class="ml-10 mt-6 text-center">
            <div class="text-h5 mt-10 mb-4">{{$t('LIST.NO_RESULT')}}</div>
            <template v-if="$queryFilters().count() > 0">
              <div><small class="grey--text">{{$t('LIST.SUGGESTION')}}</small></div>
              <div><a class="info--text" @click="clearFilters">{{$t('LIST.CLEAR_FILTER')}}</a></div>
              <!-- <div><a class="info--text" @click="newItem">{{$t('LIST.CREATE_ITEM')}}</a></div> -->
            </template>
          </div>
        </template>

        <!-- ITEM ACCORDION -->
        <template v-slot:item-accordion="item">
          <v-list-item>
            <v-list-item-action class="position-relative" style="width:140px;">
              <v-img
              class="ma-4"
              :src="getImage(item)"
              height="22px"
              width="130px"
              max-width="100%"
              contain
              />

            </v-list-item-action>

            <v-list-item-content style="min-width:200px; max-width:200px;">
              <v-list-item-title>{{item.channel.title}} <small class="grey--text">{{item.id}}</small></v-list-item-title>
              <v-list-item-subtitle><v-chip v-if="item.channel_params.test_mode" color="warning" x-small class="mr-2">Test</v-chip> {{$t('CHANNEL_SUBSCRIPTION.METHOD_' + item.channel.category.toUpperCase())}}</v-list-item-subtitle>
            </v-list-item-content>

            <div class="pl-2 width-30">
              <v-progress-linear
                rounded
                reverse
                :height="12"
                background-color="red lighten-4"
                :value="getProgressValue(item)"
                :color="getProgressColor(item)"
              />
            </div>

            <div class="pl-4 text-ellipsis">
              <small class="numeric">
                <!-- <strong :class="[getProgressColor(item) + '--text']">{{getProgressText(item)}}</strong> -->
                <strong>{{getProgressText(item)}}</strong>
              </small>
            </div>

            <div class="flex-grow" />

            <v-list-item-action>
              <div class="ml-4 text-nowrap">

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mr-2"
                      :disabled="!item.status_output || Object.keys(item.status_output).length === 0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">mdi-file</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0" dense style="width:350px;" v-if="item.status_output && Object.keys(item.status_output).length > 0">
                    <div>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon class="mr-2">mdi-file</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{$t('CHANNEL_SUBSCRIPTION.GENERATED_FILE')}}</v-list-item-title>
                          <v-list-item-subtitle>{{item.channel.title}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <v-divider />
                    <v-list-item :key="'ou'+i" v-for="(key,i) in Object.keys(item.status_output)">
                      <v-list-item-content>
                        <v-list-item-subtitle class="numeric">
                          <small>{{$moment(item.status_output[key].date).fromNow()}}</small>
                        </v-list-item-subtitle>
                        <v-list-item-title><v-chip color="warning" v-if="item.status_output[key].test_mode === true" x-small class="mr-2">Test</v-chip>{{$t('CHANNEL_SUBSCRIPTION.FILE_' + key.toUpperCase())}}</v-list-item-title>
                        <v-list-item-subtitle class="numeric">
                          <small>{{new Date(item.status_output[key].date).toLocaleString()}} - {{formatFileSize(item.status_output[key].size)}}</small>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div>
                          <v-btn icon small @click="copyText(storageUrl + '/' + item.status_output[key].path)" class="mr-2"><v-icon>mdi-content-copy</v-icon></v-btn>
                          <a :href="storageUrl + '/' + item.status_output[key].path">
                            <v-btn icon small><v-icon>mdi-download</v-icon></v-btn>
                          </a>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <permission name="channel_subscription" action="update" :related-id="item.id">
                  <v-btn icon @click.stop="editItem(item)" class="mr-2">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="removeItem(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </permission>

              </div>
            </v-list-item-action>
          </v-list-item>
        </template>

        <!-- ITEM ACCORDION CHILDREN -->
        <template v-slot:item-accordion-children="item">
            <v-list dense class="pa-0">
            <template v-if="item.contracts && item.contracts.length > 0">
            <template v-for="(contract, i) in item.contracts" >
              <v-divider :key="'dco'+i" />
              <v-list-item :key="'co'+i">

                <v-list-item-action style="width:140px;">
                </v-list-item-action>

                <v-list-item-content style="min-width:200px; max-width:200px;">
                  <v-list-item-title><v-icon class="mr-2" size="20">mdi-file-sign</v-icon>{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.TITLE')}}: {{new Date(contract.created_at).toLocaleDateString()}}</v-list-item-title>
                </v-list-item-content>

                <div class="pl-2" style="width:calc(32% - 33px)">
                  <v-progress-linear
                    rounded
                    reverse
                    :height="8"
                    background-color="red lighten-4"
                    :value="getProgressValue({contracts: [contract]})"
                    :color="getProgressColor({contracts: [contract]})"
                  />
                </div>

                <div class="pl-4 text-ellipsis">
                  <small class="numeric">
                    <strong>{{getProgressText({contracts: [contract]})}}</strong>
                  </small>
                </div>

                <div class="pl-4 text-ellipsis">
                  <small class="grey--text" v-if="contract.no_limit_time">
                    <!-- {{$t('COMMON.NO_LIMIT_TIME')}} -->
                  </small>
                  <small class="grey--text" v-else-if="contract.end_date && contract.end_date < new Date()">
                    {{$t('COMMON.END')}} : {{$moment(contract.end_date).fromNow()}}
                  </small>
                  <small class="grey--text" v-else-if="contract.start_date && contract.start_date > new Date()">
                    {{$t('COMMON.START')}} : {{$moment(contract.start_date).fromNow()}}
                  </small>
                  <small class="grey--text" v-else>
                    {{$t('COMMON.END')}} : {{$moment(contract.start_date).fromNow()}}
                  </small>
                </div>

                <div class="flex-grow" />
                <v-list-item-action style="padding-right:40px;">
                  <permission name="channel_subscription_contract" action="update">
                    <div class="ml-4 text-nowrap">
                        <v-btn icon small @click.stop="editContract(item, contract.id)" class="mr-2">
                          <v-icon size="18">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon small @click.stop="removeContract(contract.id)">
                          <v-icon size="18">mdi-delete</v-icon>
                        </v-btn>
                    </div>
                  </permission>
                </v-list-item-action>

              </v-list-item>

            </template>
          </template>
          <permission name="channel_subscription_contract" action="create">
            <v-list-item>
              <v-list-item-action style="width:140px;">
              </v-list-item-action>
                <div class="mb-4">
                    <v-btn small depressed rounded color="secondary black--text" @click="newContract(item)"><v-icon class="mr-2" size="16">mdi-plus</v-icon>{{$t('CHANNEL_SUBSCRIPTION_CONTRACT.ADD')}}</v-btn>
                </div>
            </v-list-item>
          </permission>
          </v-list>
        </template>


        <!-- SIDE-CONTENT -->
        <template v-slot:side-content>
          <div>
            <div class="text-h4">{{$t('CHANNEL_SUBSCRIPTION.BUDGET_TITLE')}}</div>
            <p class="mb-8"><strong>{{$t('CHANNEL_SUBSCRIPTION.METHOD_' + channelMethod.toUpperCase() )}}</strong></p>

            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="d-flex align-center"><small class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.TOTAL_BUDGET_BASE')}}</small></v-col>
              <v-col class="primary--text d-flex flex-column justify-center align-end text-right position-relative">
                <div class="text-h6 text-uppercase" v-if="totalBudgetBaseOpen">
                  <v-tooltip top max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <strong
                      class="numeric"
                      v-bind="attrs"
                      v-on="on"
                      >{{ $t('CHANNEL_SUBSCRIPTION.BUDGET_OPEN') }}*</strong>
                    </template>
                    {{ $t('CHANNEL_SUBSCRIPTION.BUDGET_OPEN_TEXT') }}
                  </v-tooltip>
                </div>
                <span class="text-h5" v-else><strong class="numeric">{{ totalBudgetBase.toLocaleString() }} €</strong></span>
                <span>{{ totalCreditBase.toLocaleString() }} Cr.</span>
              </v-col>
            </v-row>

            <v-divider />
            <v-row class="mt-0 mb-0">
              <v-col cols="6" class="d-flex align-center"><small class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.TOTAL_BUDGET_SPENT')}}</small></v-col>
              <v-col class="accent--text d-flex flex-column justify-center align-end text-right">
                <span class="text-h5"><strong class="numeric">{{ totalBudgetSpent.toLocaleString() }} €</strong></span>
                <span>{{ totalCreditSpent.toLocaleString() }} Cr.</span>
              </v-col>
            </v-row>

            <v-divider />
            <template v-if="channelMethod !== 'organic'">
              <v-row class="mt-0 mb-0">
                <v-col cols="6" class="d-flex align-center pb-0"><small class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.TOTAL_BUDGET_SPENT_PERCENT')}}</small></v-col>
              </v-row>
              <div class="mt-4" v-if="!loading">
                <PieChart type="PieChart" :values="pieChartValues" :key="piechartKey" class="height-30vh width-100" />
              </div>
            </template>

          </div>
        </template>


      </List>
    </PageMain>

  </div>
</template>


<script>

import { mapState } from 'vuex'

import PageHeader from '@/components/PageHeader'
import PageMain from '@/components/PageMain'
import List from '@/components/List'
import Side from "@/components/Side"
import Permission from "@/components/Permission"
import PieChart from "@/components/charts/PieChart"
import FormChannelSubscriptionStepper from '@/forms/channel_subscription/stepper/FormChannelSubscriptionStepper'
import DialogRemove from '@/components/DialogRemove'
import FormChannelSubscriptionMixins from '@/forms/channel_subscription/FormChannelSubscriptionMixins.vue'
import FormChannelSubscriptionCompactContractsForm from '@/forms/channel_subscription/compact/FormChannelSubscriptionCompact.contracts.form.vue'

const _colors = [
  [244, 67, 54],
  [255, 87, 34],
  [255, 87, 34],
  [255, 152, 0],
  [255, 152, 0],
  [255, 193, 7],
  [255, 193, 7],
  [205, 220, 57],
  [205, 220, 57],
  [139, 195, 74],
  [139, 195, 74],
  [76, 175, 80],
  [76, 175, 80],
]

const ELEMENT_NAME = 'channel_subscription'
const TABLE_NAME = 'channel_subscriptions'
const SERVICE_NAME = 'api_programmatic'

export default {
  name: 'PageXprogrammaticAllChannelSubscriptions',
  mixins: [FormChannelSubscriptionMixins],
  components: {
    List,
    PageHeader,
    PageMain,
    Permission,
    Side,
    PieChart,
    FormChannelSubscriptionStepper,
    DialogRemove,
    FormChannelSubscriptionCompactContractsForm,
  },
  data: () => ({
    loading: false,
    timeout: undefined,
    selected: [],
    items: [],
    count: 0,
    offset: 0,
    limit: 20,
    status: [1],
    viewMode: "medias-list",
    budget_estimated: 0,
    budget_spent: 0,
    channelMethod: "none",
    showEdit: false,
    showNew: false,
    showDialogRemove: false,
    piechartKey: 0,
    pieChartValues: [
      {
        label: 'test',
        value: 0.9,
        color: "#333154",
      },
      {
        label: 'test2',
        value: 0.1,
        color: "#330000",
      }
    ],
    totalBudgetBaseOpen:false,
    totalBudgetBase: 0,
    totalCreditBase: 0,
    totalBudgetSpent: 0,
    totalCreditSpent: 0,

    dialog: false,
    contractId: undefined,
    contractChannel: {},
    contractProps: {}
  }),

  computed: {
    ...mapState('user', {
      role: state => state.user.role.title,
    }),
    storageUrl () {
      return process.env.VUE_APP_FILESHARE_PUBLIC
    }
  },

  mounted () {
    this.$queryFilters().get()
    this.loadItems()
    this.attribValues()
  },

   watch: {
    channelMethod: {
      handler() {
        this.loadItems();
      },
      values: {
        handler () {
          this.attribValues()
        },
        deep: true,
      },
      loaded_values: {
        handler () {
          this.loadListItems()
        },
        deep: true,
      },
    },
  },

  methods: {

    newItem () {
      this.selected = []
      this.showNew = true
    },


    getProgressColor (item) {
      const now = new Date()
      let value = 0
      let commited = 0
      let color = 'grey'
      if (item.contracts && item.contracts.length > 0) {
        color = 'error'
        for (const contract of item.contracts) {
          if (contract.status === 1) {
            if (contract.no_limit_value === true) // unlimited
              return color = this.$vuetify.theme.currentTheme.primary
            if (
              (contract.no_limit_time === true || contract.start_date <= now)
              && (contract.no_limit_time === true || contract.end_date >= now)
              && (contract.no_limit_value === true || (contract.value > contract.used && contract.value > contract.commited))
            ) {
              value += contract.value || 0
              commited += contract.commited || 0
              color = this.$vuetify.theme.currentTheme.primary
            }
          }
        }
        return (value > 0 && commited/value > 0.8) ? 'error' : color
      }
    },

    getProgressValue (item) {
      const now = new Date()
      let value = 0
      let commited = 0
      if (item.contracts && item.contracts.length > 0) {
        for (const contract of item.contracts) {
          if (contract.status === 1) {
            if (contract.no_limit_value === true) // unlimited
            return 100
            if (
              (contract.no_limit_time  === true || contract.start_date <= now)
              && (contract.no_limit_time  === true || contract.end_date >= now)
              && (contract.no_limit_value === true || (contract.value > contract.used && contract.value > contract.commited))
            ) {
              value += contract.value || 0
              commited += contract.commited || 0
            }
          }
        }
        return Math.round((value-commited) / value * 100) // reversed
      }
      return 0
    },

    getProgressText (item) {
      const now = new Date()
      if (item.contracts && item.contracts.length > 0) {
        let value = 0
        let used = 0
        for (const contract of item.contracts) {
          if (contract.no_limit_value === true) // unlimited
            return this.$i18n.t("CHANNEL_SUBSCRIPTION.UNLIMITED")
          if (
            contract.status === 1
            && (contract.no_limit_time === true || contract.start_date <= now)
            && (contract.no_limit_time === true || contract.end_date >= now)
            && (contract.value > contract.used && contract.value > contract.commited)
          ) {
            value += contract.value || 0
            used += contract.commited || 0
          }
        }

        if (value !== 0)
          return '' + used.toLocaleString() + ' / ' + value.toLocaleString() + ' ' + (item.contracts[0].type === 'credit' ? 'Cr.' : '€')

        return this.$i18n.t("CHANNEL_SUBSCRIPTION_CONTRACT.EXPIRED")
      }
      return this.$i18n.t("CHANNEL_SUBSCRIPTION.NO_CONTRACT")
    },

    clearFilters () {
      this.$queryFilters().clear()
      this.loadItems()
    },

    editItem(item) {
      this.selected = [item];
      this.$router.push(
        `/xprogrammatic/channel_subscriptions/?edit=${this.selected[0].id}`
      );
      this.showEdit = true;
    },

    closeEdit() {
      this.showEdit = false;
      this.$router.push("/xprogrammatic/channel_subscriptions/");
    },

    isOrganic() {
      return this.channelMethod === "organic"
    },

    formatFileSize (size) {
      let unit = 'KB'
      size = size / 1024 // kb
      if (size > 100) {
        unit = 'MB'
        size = size / 1024 // mb
      }
      return (Math.round(size * 100) / 100) + ' ' + unit
    },

    copyText (txt) {
      navigator.clipboard.writeText(txt)
        .then(() => {
          console.log('copy:', txt)
        })
    },

    getColorFromValue(value = 0) {
      const part = 100 / _colors.length
      const ratio = value / part
      const index = Math.floor(ratio)
      let color = _colors[index] || _colors[_colors.length - 1]
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`
    },

    getBlueFromType(value = 0, type, nolimit) {
      const hueByType = {
        "none": {hue: 240, saturation: 35, light: 50},
        "organic": {hue: 200, saturation: 70, light: 70},
        "credit": {hue: 234, saturation: 31, light: 56},
        "aggregator": {hue: 244, saturation: 31, light: 50},
        "display": {hue: 244, saturation: 46, light: 25},
      };

      return `hsla(
          ${value < 20 && !nolimit && type !== "organic" ? 360 : hueByType[type].hue},
          ${value < 20 && !nolimit && type !== "organic" ? 70 : hueByType[type].saturation}%,
          ${hueByType[type].light}%, 1
        )`;
    },

    //
    getImage(item, size = 'original') {
      const url =
        `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.channel.controller_name.toLowerCase()}.png`
    },

    // select a group of items
    selectItems(arr) {
      this.selected = arr
    },

    // reassign selected items
    updateSelected() {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i]
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j]
          if (a.id === b.id) {
            this.selected[i] = b
            break
          }
        }
      }
    },

    goToItem(item) {
      this.loadItems()
      // item = item ? item : this.selected[0];
      // const route = `/xprogrammatic/channel_subscription/${item.id}`;
      // if (this.$router.currentRoute.path !== route) {
      //   this.$router.push(route);
      // }
    },

    // change page
    changePage(page) {
      this.page = +page
      this.searchItems(80)
    },

    // search with a timeout
    searchItems(timeout = 150) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, timeout)
    },


    // load items with filters
    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()

      // build query
      const defaultOptions = {
        order: undefined,
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,
      }

      const defaultWhere = {
          status: [1],
          // channel_subscription_contracts: true,
          // publication_count: true,
          contracts: true,
      }

      let where = {
        channel: {
          status: 1,
          required: true,
        },
      }

      if (this.channelMethod === "organic") {
        where.methods = "organic"
      } else if (this.channelMethod !== "none") {
        where.channel.category = this.channelMethod
      }

      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
        where: Object.assign({}, defaultWhere, where),
      })

      const handleResponse = (response) => {
        if (response.data) {
          this.count = response.data.count
          this.offset = response.data.offset
          this.limit = response.data.limit


          // prepare results
          let totalBudgetSpent = 0
          let totalBudgetBase = 0
          let totalCreditSpent = 0
          let totalCreditBase = 0
          let totalUsed = 0
          const pieChartValues = {}
          const items = response.data.items
          items.map(item => {
            const contracts = item.contracts
            if (contracts && contracts.length > 0) {
              item.contracts = contracts
                .filter(c => c.status === 1)
                .map(c => {

                  // pie chart
                  if (!pieChartValues[c.id]) {
                    pieChartValues[c.id] = { label:item.channel.title, value:0, color: item.channel.color || '#' + ('' + Date.now() * Math.random()).slice(0,6) }
                  }

                  if (c.no_limit_value) this.totalBudgetBaseOpen = true

                  // total budget
                  if (c.type === 'credit') {
                    totalCreditSpent += c.used
                    totalCreditBase += c.value
                    if (c.price_total) {
                      const price_unit = c.price_total / c.value
                      totalBudgetSpent += price_unit
                      totalUsed += price_unit
                      pieChartValues[c.id].value += price_unit
                      totalBudgetBase += c.price_total
                    }
                  } else {
                    totalUsed += c.used
                    pieChartValues[c.id].value += c.used
                    totalBudgetSpent += c.used
                    totalBudgetBase += c.value
                  }
                  return c
                })
            }

            // compile pie chart
            this.pieChartValues = Object.keys(pieChartValues)
              .sort((a, b) => pieChartValues[b].value > pieChartValues[a].value ? 1 : -1)
              .map((key, i) => pieChartValues[key])
              .filter(v => v.value)
            this.piechartKey++


            return item
          })

          this.totalBudgetSpent = Math.round(totalBudgetSpent * 100) / 100
          this.totalBudgetBase = Math.round(totalBudgetBase * 100) / 100
          this.totalCreditSpent = Math.round(totalCreditSpent * 100) / 100
          this.totalCreditBase = Math.round(totalCreditBase * 100) / 100
          this.items = items


        } else {
          this.items = []
          this.count = 0
          this.offset = 0
          this.limit = 0
        }
        this.updateSelected()
      }

      // request
      this.$services[SERVICE_NAME][ELEMENT_NAME]
        .search(query)
        .then(response => {
          handleResponse(response)
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_programmatic.channel_subscription.remove({ where: { id: ids }, options: {limit:ids.length}})
        .then(response => {
          if (response.data) {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          }
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    // remove item
    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    // CONTRACTS

    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    },

    newContract(channel_subscription) {
      this.currentChannel = channel_subscription.channel
      this.currentChannelSubscription = channel_subscription
      this.contractProps = {channel: channel_subscription.channel, channel_subscription}
      this.contractId = undefined
      this.$nextTick(() => {
        this.dialog = true
      })
    },

    editContract(channel_subscription, id) {
      this.currentChannel = channel_subscription.channel
      this.currentChannelSubscription = channel_subscription
      this.contractProps = {channel: channel_subscription.channel, channel_subscription}
      this.contractId = id
      this.$nextTick(() => {
        this.dialog = true
      })
    },

    removeContract(id) {
      if (id)
        return this.$services.api_programmatic.channel_subscription_contract
          .remove({ where: { id } })
          .then((response) => this.loadItems())
    },

    closeDialog() {
      this.dialog = false
      this.currentChannelSubscription = undefined
      this.currentChannel = undefined
      this.contractProps = undefined
      this.contractId = undefined
      setTimeout(() => {
        this.loadItems()
      }, 100)

    },


  }




}
</script>
